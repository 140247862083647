import React, { useEffect, useState } from 'react';
import {
  Button,
  CircularProgress,
  ClickAwayListener,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  Snackbar
} from '@material-ui/core';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';
import clsx from 'clsx';
import PowerModeInput from 'power-mode-input';
import * as PropTypes from 'prop-types';
import Recaptcha from '../Recaptcha';
import { useApolloClient } from '@apollo/client';
import { SEND_CONTACT_US } from '../../apollo/mutations';
import { APP_LANGUAGE } from '../../i18n';


ContactUsForm.propTypes = {
  popover: PropTypes.bool,
  text: PropTypes.string.isRequired
};

ContactUsForm.defaultProps = {
  popover: false
};


export default function ContactUsForm({text, popover}) {
  const classes = useStyles();
  const {t} = useTranslation();
  const client = useApolloClient();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [state, setState] = useState({
    fullName: '',
    email: '',
    message: ''
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [error, setError] = useState({
    fullName: '',
    email: '',
    message: ''
  });
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [messageSnackBar, setMessageSnackBar] = useState('emailSuccess');
  const [loading, setLoading] = useState(false);
  const [sendSuccess, setSendSuccess] = useState(false);
  const [recaptcha, setRecaptcha] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setState({
      fullName: '',
      email: '',
      message: ''
    });
    setErrorMessage('');
    setError({
      fullName: '',
      email: '',
      message: ''
    });
    setOpenSnackBar(false);
    setRecaptcha(false);
    setSendSuccess(false);
  };

  const handleChange = (event) => {
    setError({
      ...error,
      [event.target.name]: ''
    });
    setState({...state, [event.target.name]: event.target.value});
  };

  const handleSubmitOK = async () => {
    client.mutate({
      mutation: SEND_CONTACT_US,
      variables: {
        input: {
          email: state['email'],
          fullname: state['fullName'],
          message: state['message']
        }
      },
      context: {
        headers: {
          'Accept-Language': localStorage.getItem(APP_LANGUAGE)
        }
      }
    }).then(({data}) => {
        const {success, errors} = data.contactUs;
        if (success) {
          setLoading(false);
          setSendSuccess(true);
          setOpenSnackBar(true);
          setRecaptcha(false);
          setMessageSnackBar('emailSuccess');
        } else {
          setLoading(false);
          const temp = {};
          errors.forEach(error => {
            temp[error.field] = error.messages.join(' ,');
          });

          setError({
            ...error,
            ...temp
          });
          setRecaptcha(false);
        }
      }
    ).catch(() => {
      setMessageSnackBar('emailError');
      setOpenSnackBar(true);
      setLoading(false);
      setRecaptcha(false);
    });
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    const temp = {...error};
    let errorFlag = false;
    ['fullName', 'email', 'message'].forEach((item) => {
      if (!Boolean(state[item]) || Boolean(error[item])) {
        temp[item] = 'required';
        errorFlag = true;
      }
    });

    if (errorFlag) {
      setError(temp);
      setLoading(false);
    } else {
      setRecaptcha(true);
      // handleClose();
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  useEffect(() => {
    const messageInput = typeof document === `undefined` ? '' : document.querySelector(`#message-input${popover ? '-popover' : ''}`);
    const emailInput = typeof document === `undefined` ? '' : document.querySelector(`#email-input${popover ? '-popover' : ''}`);
    const fullNameInput = typeof document === `undefined` ? '' : document.querySelector(`#full-name-input${popover ? '-popover' : ''}`);

    if (emailInput && messageInput && fullNameInput) {
      PowerModeInput.make(document.querySelector(`#message-input${popover ? '-popover' : ''}`), {
        color: '#022055'
      });
      PowerModeInput.make(document.querySelector(`#email-input${popover ? '-popover' : ''}`), {
        color: '#022055'
      });
      PowerModeInput.make(document.querySelector(`#full-name-input${popover ? '-popover' : ''}`), {
        color: '#022055'
      });
    }
  }, [popover, open]);

  const form = <div>
    <Grid container spacing={2} className={classes.contactForm}>
      <form noValidate autoComplete="off" className={classes.formContainer}>
        <Grid item container xs={12} className={classes.formContainer} spacing={2}>
          <Grid item sm={popover ? 12 : 6} xs={12}>
            {!popover && <InputLabel shrink htmlFor="full-name-input" className={classes.labelForm}>
              {`${t('fullName')} *`}
            </InputLabel>}
            <Input
              id={`full-name-input${popover ? '-popover' : ''}`}
              name={'fullName'}
              value={state['fullName']}
              placeholder={t('fullName')}
              error={Boolean(error['fullName'])}
              onChange={handleChange}
              fullWidth
              disableUnderline={!Boolean(error['fullName'])}
              className={popover ? classes.inputPopover : classes.input}
            />
            <FormHelperText className={popover ? null : classes.helperText}
                            error>{t(error['fullName'])}</FormHelperText>
          </Grid>
          <Grid item sm={popover ? 12 : 6} xs={12}>
            {!popover && <InputLabel shrink htmlFor="email-input" className={classes.labelForm}>
              {`${t('email')} *`}
            </InputLabel>}
            <Grid item xs={12}>
              <Input
                id={`email-input${popover ? '-popover' : ''}`}
                name={'email'}
                placeholder={t('email')}
                className={popover ? classes.inputPopover : classes.input}
                disableUnderline={!Boolean(error['email'])}
                fullWidth
                variant={popover ? 'outlined' : 'filled'}
                value={state['email']}
                onBlur={(e) => {
                  if (e.target.value && !(/\S+@\S+\.\S+/.test(e.target.value))) {
                    setError({
                      ...error,
                      [e.target.name]: 'validEmail'
                    });
                  }
                }}
                error={Boolean(error['email'])}
                onChange={handleChange}
              />
              <FormHelperText className={popover ? null : classes.helperText} error>{t(error['email'])}</FormHelperText>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {!popover && <InputLabel shrink htmlFor="email-input" className={classes.labelForm}>
              {`${t('message')} *`}
            </InputLabel>}
            <Grid item xs={12}>
              <Input
                id={`message-input${popover ? '-popover' : ''}`}
                name={'message'}
                placeholder={t('message')}
                className={popover ? classes.inputPopover : classes.input}
                disableUnderline={!Boolean(error['message'])}
                fullWidth
                multiline
                rows={4}
                value={state['message']}
                error={Boolean(error['message'])}
                onChange={handleChange}
              />
              <FormHelperText className={popover ? null : classes.helperText}
                              error>{t(error['message'])}</FormHelperText>
            </Grid>
          </Grid>
        </Grid>
      </form>
      <Grid item sm={popover ? 12 : 6} xs={12}>
        <Typography variant={'caption'} color={'primary'} className={classes.newsletterTitle}>
          {Boolean(errorMessage) && errorMessage}
        </Typography>
      </Grid>
      <Recaptcha submit={recaptcha} callback={handleSubmitOK} />
      <Grid item xs={12} container justify={popover ? 'flex-end' : 'flex-start'}>
        <Grid item sm={popover ? 6 : 2} xs={6}>
          <Button
            variant={'contained'}
            data-action="submit"
            className={clsx(classes.contactButton, !popover && classes.contactButtonReverse)}
            onClick={handleSubmit}
            endIcon={loading && <CircularProgress size={20} color={popover ? 'secondary' : 'primary'} />}
            classes={{disabled: !popover ? classes.buttonDisabled : classes.buttonDisabledReverse}}
            disabled={sendSuccess}
          >
            {t('send')}
          </Button>
        </Grid>
      </Grid>
    </Grid>
    <Snackbar
      anchorOrigin={{vertical: 'top', horizontal: 'right'}}
      open={openSnackBar}
      onClose={() => setOpenSnackBar(false)}
      message={t(messageSnackBar)}
    />
  </div>;

  if (popover) {
    return (
      <div>
        <ClickAwayListener onClickAway={handleClose}>
          <div data-sal={'zoom-in'}
               data-sal-delay="300"
               data-sal-duration="1000"
               data-sal-easing="ease">
            <div
              id={'contact-form'}
              onClick={handleClick}
              onKeyDown={handleClick}
              role="button"
              tabIndex="0"
            >
              <Button className={classes.menuItemContactUs} aria-describedby={id} variant="contained"
                      color="primary">{text}</Button>
              <ContactMailIcon id={'contact-form-icon'} color={'secondary'} className={classes.menuIconContactUs} />
            </div>
            {open ? (
              <div className={classes.contactFormContainer}>
                <Typography variant={'h4'} color={'primary'} gutterBottom>
                  {t('contactUs')}
                </Typography>
                {form}
              </div>
            ) : null}
          </div>
        </ClickAwayListener>
      </div>
    );
  }

  return form;
}
