import React, { useState } from 'react';
import {
  AppBar,
  Button,
  ClickAwayListener,
  Divider,
  Grid,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Toolbar,
  Typography
} from '@material-ui/core';
import clsx from 'clsx';
import MenuIcon from '@material-ui/icons/Menu';
import Logo from '../../assets/svg/logoTop.svg';
import { useStyles } from './styles';
import ContactUsForm from '../ContactUs';
import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';
import TranslationSelector from '../TranslationSelector';

export default function Header({location}) {
  const classes = useStyles();
  const {t} = useTranslation();
  const pathname = location.pathname.replace(/\//g, '');
  const [activeTab, setActiveTab] = useState(pathname);

  const menuList = (t) => [
    {
      id: 'services',
      title: t('servicesPage'),
      link: '/services/'
    },
    {
      id: 'industries',
      title: t('industries'),
      link: '/industries/'
    },
    {
      id: 'about-us',
      title: t('aboutUs'),
      link: '/about-us/'
    },
    {
      id: 'careers',
      title: t('careerTitle'),
      link: '/careers/'
    }
  ];

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <AppBar position="static" className={classes.headerContainer}>
      <Toolbar className={classes.header}>
        <Grid container justify={'flex-end'} alignItems={'center'}>
          {menuList(t).map(item => (
            <Grid
              key={`menu-item-${item.id}`}
              item
              className={classes.menuItem}
              onClick={() => setActiveTab(item.id)}
            >
              <Typography
                key={`item-${item.id}`}
                color="inherit"
                onClick={() => navigate(item.link)}
                className={clsx(classes.menuItemLabel, item.id === activeTab ? classes.menuItemActive : null)}
              >
                {item.title}
              </Typography>
            </Grid>
          ))}
          <Grid item className={classes.menuItem}>
            <ContactUsForm text={t('contactUs')} popover />
          </Grid>
          <Grid item className={classes.menuItemLanguage}>
            <TranslationSelector />
          </Grid>
        </Grid>
        <Button
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          aria-label={'menu-icon'}
          onClick={handleToggle}
          color="inherit"
          className={classes.menuDrop}
        >
          <MenuIcon />
        </Button>
        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal
                className={classes.menuDropPaper}>
          {({TransitionProps, placement}) => (
            <Grow
              {...TransitionProps}
              style={{transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <div>
                    <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                      {menuList(t).map(item => (
                        <MenuItem
                          key={`menu-dropdown-item-${item.id}`}
                          onClick={(e) => {
                            handleClose(e);
                            navigate(item.link);
                          }}
                        >
                          {item.title}
                        </MenuItem>
                      ))}
                    </MenuList>
                    <Divider />
                    <TranslationSelector header />
                  </div>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Toolbar>
      <div className={classes.logo}>
        <Logo className={classes.icon} onClick={() => navigate('/')} />
      </div>
    </AppBar>
  );
}
