import { makeStyles } from '@material-ui/core';


export const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: theme.palette.primary.dark,
  },
  footerContainer: {
    padding: '4.5rem 18.5rem',
    [theme.breakpoints.down('md')]: {
      padding: '4rem 4.5rem',
      flexDirection: 'row',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '2rem 2.5rem',
      flexDirection: 'row',
      justifyContent: 'center',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '2rem 1.5rem',
      flexDirection: 'row',
      justifyContent: 'center',
    },
  },
  title: {
    textTransform: 'uppercase',
    paddingBottom: '1rem',
    color: 'white',
    fontWeight: 'bold',
    fontSize: 13
  },
  subtitle: {
    textTransform: 'uppercase',
    fontSize: 13,
    paddingBottom: '1rem',
    color: theme.palette.primary.contrastTextLess,
    fontWeight: 'bold',
    cursor: 'pointer',
    '&:hover': {
      color: 'white',
    }
  },
  menuItem: {
    margin: '0px 15px 5px',
  },
  menuItemActive: {
    color: '#60C7F8',
    borderBottom: '2px solid #60C7F8',
  },
  divider: {
    backgroundColor: theme.palette.primary.contrastTextLess,
    height: '100%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      height: 1,
      margin: '2rem 0'
    }
  },
  dividerHorizontal: {
    backgroundColor: theme.palette.primary.contrastTextLess,
    width: '100%',
  },
  newsletter: {
    width: '100%',
    backgroundColor: 'white',
    marginBottom: theme.spacing(1),
    '&:hover': {
      color: 'white',
    },
  },
  newsletterTitle: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: 13
  },
  newsletterError: {
    color: 'white',
    fontSize: 13
  },
  newsletterButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.main,
    textTransform: 'uppercase',
    padding: '.5rem 2rem',
    fontWeight: 'bold',
    borderRadius: 10,
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.secondary.main,
      borderColor: 'white',
    },
  },
  buttonDisabled: {
    color: `${theme.palette.secondary.main} !important`,
    backgroundColor: `${theme.palette.primary.main} !important`,
    borderColor: 'white !important',
    opacity: 0.7
  },
  footerText: {
    padding: '2rem 0 3rem',
    color: 'white',
    fontSize: 13
  },
  itemContainer: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      textAlign: 'center'
    }
  },
  logo: {
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      justifyContent: 'center',
    }
  },
  footerIcon: {
    cursor: 'pointer',
    marginLeft: '.5rem'
  },
  gridItemContainer: {
    width: '100%',
    display: 'grid',
    gridGap: '.5rem',
    gridTemplateColumns: 'auto auto auto auto',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'auto auto',
    },
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: 'auto',
    }
  },
}));
