import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Grid } from '@material-ui/core';
import { useStyles } from './styles';
import { APP_LANGUAGE } from '../../i18n';
import clsx from 'clsx';


function TranslationSelector({header}) {
  const classes = useStyles();
  const {i18n} = useTranslation();
  const [active, setActive] = useState(i18n.language);

  useEffect(() => {
    localStorage.setItem(APP_LANGUAGE, i18n.language);
  }, [i18n.language]);

  const onClickLanguage = (language) => {
    if (i18n.language === language) return;
    i18n.changeLanguage(language);
    setActive(language);
    localStorage.setItem(APP_LANGUAGE, language);
  };

  return (
    <Grid item container justify={'center'} className={clsx(classes.container, classes.containerHeader)}>
      <Grid item container
            justify={'space-evenly'}
            className={clsx(classes.item, header && classes.itemHeader,
              active === 'es' && classes.active, active === 'es' && header && classes.activeHeader)}
            onClick={() => {
              onClickLanguage('es');
            }}
      >
        ES
      </Grid>
      <Divider className={clsx(classes.divider, classes.dividerHeader)} />
      <Grid item container
            justify={'space-evenly'}
            className={clsx(classes.item, header && classes.itemHeader,
              active === 'en' && classes.active, active === 'en' && header && classes.activeHeader)}
            onClick={() => {
              onClickLanguage('en');
            }}
      >
        EN
      </Grid>
    </Grid>
  );
}

export default TranslationSelector;
