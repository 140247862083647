import React, { useEffect, useState } from "react"
import { loadReCaptcha, ReCaptcha } from "react-recaptcha-v3"
import { useApolloClient } from "@apollo/client"
import { CHECK_RECAPTCHA } from "../../apollo/queries"
import { Snackbar } from "@material-ui/core"
import { useTranslation } from "react-i18next"


function Recaptcha({ submit, callback }) {
  const client = useApolloClient()
  const { t } = useTranslation()
  const [openSnackBar, setOpenSnackBar] = useState(false)

  useEffect(() => {
    loadReCaptcha(process.env.GATSBY_RECAPTCHA_SITE_TOKEN)
  }, [])

  const verifyCallback = async (token) => {
    client.query({
      query: CHECK_RECAPTCHA,
      variables: {
        token
      }
    }).then(({ data }) => {
        if (data.verifyRecaptcha) {
          callback()
        } else {
          setOpenSnackBar(true)
        }
      }
    )
  }

  return (
    <div>
      {submit &&
      <ReCaptcha
        action="submit"
        sitekey={process.env.GATSBY_RECAPTCHA_SITE_TOKEN}
        verifyCallback={verifyCallback}
      />
      }
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={openSnackBar}
        onClose={() => setOpenSnackBar(false)}
        message={t("emailError")}
      />
    </div>
  )
}

export default Recaptcha
