import { makeStyles } from '@material-ui/core';
import { keyframes } from '../../assets/styles/keyframes';


export const useStyles = makeStyles(theme => ({
  divider: {
    width: 150,
    height: 1,
    backgroundColor: theme.palette.primary.main,
    // transition: 'opacity 1s cubic-bezier(.215,.61,.355,1)',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  dividerSecondary: {
    backgroundColor: theme.palette.secondary.main,
  },
  titleText: {
    fontFamily: 'Montserrat, sans serif',
    fontSize: 30,
    fontWeight: 900,
    margin: '0 4rem',
    textTransform: 'uppercase',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      margin: 0,
    },
  },
  title: {
    width: 'fit-content',
    [theme.breakpoints.down('sm')]: {
      margin: 'auto',
    },
  },
  titleFocusFirst: {
    fontWeight: 'bold',
    paddingRight: '.5rem',
    [theme.breakpoints.down('sm')]: {
      margin: 'auto',
    },
  },
  titleFocusSecond: {
    fontWeight: 'bold',
    paddingLeft: '.5rem',
    [theme.breakpoints.down('sm')]: {
      margin: 'auto',
    },
  },
  titleContainer: {
    paddingBottom: '1rem',
    width: 'fit-content',
    textAlign: 'center',
    display: 'flex',
  },
  ...keyframes,
}));
