import { makeStyles } from '@material-ui/core';
import { keyframes } from '../../assets/styles/keyframes';


export const useStyles = makeStyles(theme => ({
  headerContainer: {
    backgroundColor: '#124AB1',
    position: 'sticky',
    top: 0,
  },
  header: {
    padding: '0 17.5rem',
    minHeight: 55,
    [theme.breakpoints.down('md')]: {
      padding: '0 4rem',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0 1rem',
    },
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },
  menuItem: {
    margin: '0px 15px',
    cursor: 'pointer',
    [theme.breakpoints.down('xs')]: {
      margin: 0,
    },
  },
  menuItemLanguage: {
    position: 'absolute',
    right: 10,
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
      display: 'none'
    },
  },
  menuItemActive: {
    color: theme.palette.primary.light,
    borderBottom: `2px solid ${theme.palette.primary.light}`,
  },
  menuItemLabel: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    fontSize: 13,
    lineHeight: 2,
    '&:hover': {
      color: theme.palette.primary.light,
      borderBottom: `2px solid ${theme.palette.primary.light}`,
    },
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  menuDrop: {
    borderRadius: 8,
    fontWeight: '600',
    fontSize: 14,
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  logo: {
    width: '30rem',
    height: '130%',
    overflow: 'hidden',
    backgroundColor: 'white',
    color: 'blue',
    display: 'block',
    position: 'absolute',
    zIndex: 1000000,
    clipPath: 'polygon(0 0, 100% 0, 96.5% 100%, 0% 100%)',
    textAlign: 'right',
    padding: '.2rem 4.5rem .2rem 0',
    [theme.breakpoints.down('md')]: {
      width: '15.5rem',
      clipPath: 'polygon(0 0, 100% 0, 88.5% 100%, 0% 100%)',
    },
    [theme.breakpoints.down('sm')]: {
      width: '12rem',
      padding: '.2rem 4.5rem .2rem 1.5rem',
      clipPath: 'polygon(0 0, 100% 0, 88.5% 100%, 0% 100%)',
    },
    [theme.breakpoints.down('xs')]: {
      width: '10rem',
      padding: '.2rem 2.5rem .2rem 1.5rem',
      clipPath: 'polygon(0 0, 100% 0, 88.5% 100%, 0% 100%)',
    },
  },
  menuDropPaper: {
    zIndex: 10000000,
    marginRight: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      marginRight: theme.spacing(1),
    },
  },
  icon: {
    cursor: 'pointer',
    zIndex: 100000000,
  },
  translation: {

  },
  ...keyframes,
}));
