import { makeStyles } from '@material-ui/core';


export const useStyles = makeStyles(theme => ({
  menuItemContactUs: {
    paddingRight: '1.4rem',
    paddingLeft: '1.4rem',
    backgroundColor: 'white',
    borderRadius: 8,
    fontWeight: '600',
    fontSize: 14,
    color: theme.palette.primary.main,
    border: '1px solid transparent',
    textTransform: 'uppercase',
    '&:hover': {
      color: theme.palette.primary.light,
      borderColor: theme.palette.primary.light,
      backgroundColor: theme.palette.primary.main,
      borderBottom: `1px solid ${theme.palette.primary.light}`,
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  menuIconContactUs: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'inline',
      marginTop: '.3rem',
    },
  },
  contactFormContainer: {
    padding: `${theme.spacing(3)}px ${theme.spacing(3)}px`,
    position: 'absolute',
    backgroundColor: 'white',
    border: `1px solid ${theme.palette.border}`,
    borderRadius: 15,
    margin: '.5rem 16rem',
    right: 25,
    width: '18rem',
    [theme.breakpoints.down('md')]: {
      right: 15,
      width: '17rem',
      margin: '.5rem 0',
    },
    [theme.breakpoints.down('sm')]: {
      right: 15,
      width: '14rem',
      margin: '.5rem 0',
    },
  },
  contactForm: {
    padding: `${theme.spacing(1)}px 0`,
  },
  paper: {
    margin: theme.spacing(2),
    width: 350,
    borderRadius: 15,
  },
  textfield: {
    width: '100%',
    background: 'white',
    borderRadius: 6,
  },
  contactButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.main,
    fontWeight: 'bold',
    width: '100%',
    textTransform: 'uppercase',
    padding: '.5rem 2rem',
    borderRadius: 6,
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.secondary.main,
      borderColor: 'white',
    },
  },
  contactButtonReverse: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.secondary.main,
      backgroundColor: theme.palette.primary.main,
      borderColor: 'white',
    },
  },
  buttonDisabled: {
    color: `${theme.palette.primary.main} !important`,
    backgroundColor: `${theme.palette.secondary.main} !important`,
    borderColor: 'white !important',
    opacity: 0.7
  },
  buttonDisabledReverse: {
    color: `${theme.palette.secondary.main} !important`,
    backgroundColor: `${theme.palette.primary.main} !important`,
    borderColor: 'white !important',
    opacity: 0.7
  },
  input: {
    padding: `${theme.spacing(.8)}px ${theme.spacing(1)}px`,
    background: 'white',
  },
  inputPopover: {
    border: `1px solid ${theme.palette.border}`,
    padding: `${theme.spacing(.8)}px ${theme.spacing(1)}px`,
    background: 'white',
  },
  formContainer: {
    margin: 'auto',
    width: '100%',
  },
  labelForm: {
    color: 'white',
    textAlign: 'left',
    fontWeight: 'bold',
    paddingBottom: '1rem',
    fontSize: 22,
    paddingLeft: '.6rem',
  },
  helperText: {
    color: 'white !important',
    paddingLeft: '.6rem !important',
  },
  menuDropPaper: {
    zIndex: 10000000,
    marginRight: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      marginRight: theme.spacing(1),
    },
  },
}));
