import gql from 'graphql-tag';

export const SEND_EMAIL = gql`
query sendMail($email:String!, $subject: String!, $salutation: String!, $body: [String]!, $regards:String!){
    sendMail(email:$email, subject:$subject, salutation:$salutation, body:$body, regards:$regards)
  }
`;

export const CHECK_RECAPTCHA = gql`
query Verify($token: String!) {
  verifyRecaptcha(token: $token)
  }
`;
