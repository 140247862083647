import gql from 'graphql-tag';

export const SEND_CONTACT_US = gql`
mutation contactUs($input: ContactUsInput!){
  contactUs(input: $input){
    success
    errors{
      field
      messages
    }
  }
}
`;

export const SEND_NEWSLETTER = gql`
mutation newsLetters($input: NewsLetterInput!){
  newsLetters(input: $input){
    success
    errors{
      field
      messages
    }
  }
}
`;

export const SEND_CAREERS = gql`
mutation careers($input: CareersInput!){
  careers(input: $input){
      success
      errors {
        field    
        messages
      }
    }
  }
`;
