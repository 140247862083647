import { Divider, Grid, Typography } from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useStyles } from './styles';

CustomTitle.defaultProps = {
  color: 'primary'
};

CustomTitle.propTypes = {
  color: PropTypes.string,
  title: PropTypes.string.isRequired,
  customClasses: PropTypes.string,
};

export function CustomTitle({title, color, customClasses}) {
  const classes = useStyles();
  return (
    <Grid item container wrap={'nowrap'} alignItems={'center'} justify={'center'}>
      <Divider
        className={clsx(classes.divider, color === 'secondary' && classes.dividerSecondary)}
        data-sal-duration="500"
        data-sal={'flip-right'}
      />
      <Typography color={color} align={'center'} className={clsx(classes.titleText, customClasses)}
                  data-sal={'slide-down'}
                  // data-sal-delay="300"
                  data-sal-duration="500"
                  data-sal-easing="ease"
      >
        {title}
      </Typography>
      <Divider
        className={clsx(classes.divider, color === 'secondary' && classes.dividerSecondary)}
        data-sal-duration="500"
        data-sal={'flip-left'}
      />
    </Grid>
  );
}

CustomTitleBySection.defaultProps = {
  color: 'primary',
  align: 'center'
};

export function CustomTitleBySection({title, first, second, color, align}) {
  const classes = useStyles();
  const titlePart = title.split(' ');

  return (
    <Grid
      item
      container
      className={classes.titleContainer}
      justify={align}
    >
      <Typography
        color={color}
        variant={'h5'}
        align={'center'}
        className={clsx(classes.title, first && classes.titleFocusFirst)}
        data-sal={'fade'}
        data-sal-delay="300"
        data-sal-easing="ease"
      >
        {titlePart[0]}
      </Typography>
      <Typography
        color={color}
        variant={'h5'}
        align={'center'}
        className={clsx(classes.title, second && classes.titleFocusSecond)}
        data-sal={'fade'}
        data-sal-delay="300"
        data-sal-easing="ease"
      >
        {titlePart.slice(1, titlePart.length).join(' ')}
      </Typography>
    </Grid>
  );
}
