import { makeStyles } from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';


export const useStyles = makeStyles(theme => ({
  container: {
    width: '10rem',
    margin: 'auto'
  },
  containerHeader: {
    width: 'fit-content',
    margin: 'auto'
  },
  item: {
    textAlign: 'center',
    padding: '.6rem 0',
    cursor: 'pointer',
    borderRadius: 25,
    color: 'white',
    width: '3rem',
    '&:hover': {
      backgroundColor: fade(theme.palette.secondary.dark, 0.5),
    },
  },
  itemHeader: {
    color: 'black',
    padding: '.8rem .5rem',
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: 'transparent',
    },
  },
  divider: {
    width: 1,
    color: 'white',
    height: 30,
    background: 'white',
    margin: 'auto .5rem',
  },
  dividerHeader: {
    background: theme.palette.border,
  },
  active: {
    backgroundColor: fade(theme.palette.secondary.dark, 0.4),
  },
  activeHeader: {
    color: theme.palette.primary.main,
    backgroundColor: 'transparent',
  }
}));
