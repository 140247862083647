export const menuList = (t) => [
  {
    id: 1,
    title: t('home'),
    link: '/',
    subitems: [
      {
        id: 'our-services',
        title: t('ourServices'),
        link: '/',
      },
      {
        id: 'why-dekoding',
        title: t('whyDekoding'),
        link: '/',
      },
      // {
      //   id: 'customer-stories',
      //   title: t('customerStoriesFooter'),
      //   link: '/',
      // },
      {
        id: 'industries',
        title: t('industries'),
        link: '/',
      },
      {
        id: 'our-location',
        title: t('ourLocation'),
        link: '/',
      },
    ]
  },
  {
    id: 2,
    title: t('servicesPage'),
    link: '/services/',
    subitems: [
      {
        id: 'our-services',
        title: t('ourServices'),
        link: '/',
      },
      // {
      //   id: 'see-also',
      //   title: t('seeAlso'),
      //   link: '/',
      // },
      {
        id: 'lets-get-to-work',
        title: t('letsGetToWork'),
        link: '/',
      },
    ]
  },
  {
    id: 3,
    title: t('industries'),
    link: '/industries/',
    subitems: [
      {
        id: 'our-industry-expertise',
        title: t('ourIndustryExpertise'),
        link: '/',
      },
      // {
      //   id: 'see-also',
      //   title: t('seeAlso'),
      //   link: '/',
      // },
      {
        id: 'lets-get-to-work',
        title: t('letsGetToWork'),
        link: '/',
      },
    ]
  },
  {
    id: 4,
    title: t('careers'),
    link: '/careers/',
    subitems: [
      {
        id: 'lorem-ipsum',
        title: t('careerTitle'),
        link: '/',
      },
    ]
  }
];
