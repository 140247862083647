import React, { useEffect, useState } from 'react';
import { Facebook, LinkedIn } from '@material-ui/icons';
import { Button, CircularProgress, Divider, Grid, TextField, Typography } from '@material-ui/core';
import Logo from '../../assets/svg/logoBlanco.svg';
import { useStyles } from './styles';
import { menuList } from './constants';
import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';
import PowerModeInput from 'power-mode-input';
import Recaptcha from '../Recaptcha';
import { useApolloClient } from '@apollo/client';
import { SEND_NEWSLETTER } from '../../apollo/mutations';
import i18n from 'i18next';
import { APP_LANGUAGE } from '../../i18n';


export default function Footer() {
  const classes = useStyles();
  const {t} = useTranslation();
  const client = useApolloClient();
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState(false);
  const [powerInputFlag, setPowerInputFlag] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sendSuccess, setSendSuccess] = useState(false);
  const [recaptcha, setRecaptcha] = useState(false);

  useEffect(() => {
    const emailInput = document.querySelector('#email-input-newsletter');

    if (emailInput) {
      PowerModeInput.make(document.querySelector('#email-input-newsletter'), {
        color: '#022055'
      });
    }
  }, [powerInputFlag]);

  const powerInput = typeof document === `undefined` ? '' : document.querySelectorAll('[id*="powerinput_"]');

  useEffect(() => {
    if (powerInput.length && !powerInputFlag) {
      powerInput[0].style.setProperty('position', 'absolute', 'important');
      setPowerInputFlag(true);
    }
  }, [powerInput, powerInputFlag]);

  useEffect(() => {
    if(i18n.language) {
      setMessage('');
    }
    // eslint-disable-next-line
  }, [i18n.language]);

  const handleSubmitOK = async () => {
    client.mutate({
      mutation: SEND_NEWSLETTER,
      variables: {
        input: {
          email
        }
      },
      context: {
        headers: {
          'Accept-Language': localStorage.getItem(APP_LANGUAGE)
        }
      }
    }).then(({data}) => {
        if (data.newsLetters.success) {
          setError(false);
          setLoading(false);
          setMessage('thanksForSubscribe');
          setSendSuccess(true);
          setRecaptcha(false);
        } else {
          const {errors} = data.newsLetters;
          setLoading(false);
          setError(true);
          setMessage(errors[0].messages[0]);
          setRecaptcha(false);
        }
      }
    ).catch(response => {
      setLoading(false);
      setError(true);
      setRecaptcha(false);
    });
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();

    if (!email) {
      setError(true);
      setMessage(t('validEmail'));
      setLoading(false);
    } else {
      if (!error) {
        setRecaptcha(true);
      } else {
        setLoading(false);
      }
    }
  };

  const handleOnChange = (e) => {
    setEmail(e.target.value);
    setError(false);
    setMessage('');
  };

  return (
    <Grid container className={classes.container}>
      <Grid item container xs={12} className={classes.footerContainer}>
        <Grid item container sm={5} md={4} xs={12} className={classes.logo} spacing={1}>
          <Grid item>
            <Logo onClick={() => navigate('/')} />
          </Grid>
          <Grid item xs={12}>
            <Typography color={'secondary'} className={classes.newsletterTitle}>
              {t('newsletter')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id={'email-input-newsletter'}
              label={t('email')}
              variant={'filled'}
              className={classes.newsletter}
              value={email}
              error={error}
              onBlur={(e) => {
                if (e.target.value && !(/\S+@\S+\.\S+/.test(e.target.value))) {
                  setError(true);
                  setMessage(t('validEmail'));
                }
              }}
              onChange={handleOnChange}
            />
            <Typography variant={'caption'} color={'primary'} className={classes.newsletterError}>
              {Boolean(message) && t(message)}
            </Typography>
          </Grid>
          <Grid item container justify={'space-between'}>
            <Grid item>
              <div data-sal={'zoom-in'}
                   data-sal-delay="300"
                   data-sal-duration="1000"
                   data-sal-easing="ease"
              >
                <Button
                  variant={'contained'}
                  className={classes.newsletterButton}
                  onClick={handleSubmit}
                  endIcon={loading && <CircularProgress size={20} color={'secondary'} />}
                  disabled={sendSuccess}
                  classes={{disabled: classes.buttonDisabled}}
                >
                  {t('subscribe')}
                </Button>
              </div>
            </Grid>
            <Recaptcha submit={recaptcha} callback={handleSubmitOK} />
            <Grid item>
              <a aria-label="Facebook" target="_blank" rel="noopener noreferrer"
                 href="https://www.facebook.com/DekodingCompany">
                <Facebook
                  color={'secondary'}
                  fontSize={'large'}
                  className={classes.footerIcon}
                />
              </a>
              <a aria-label="LinkedIn" target="_blank" rel="noopener noreferrer"
                 href="https://www.linkedin.com/company/dekoding/?originalSubdomain=pe">
                <LinkedIn
                  color={'secondary'}
                  fontSize={'large'}
                  className={classes.footerIcon}
                />
              </a>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={1} md={1} xs={12} container justify={'center'}>
          <Divider orientation="vertical" flexItem className={classes.divider} />
        </Grid>
        <Grid item container sm={5} md={7} xs={12} className={classes.logo} justify={'center'}>
          <div className={classes.gridItemContainer}>
            {menuList(t).map(item => (
              <Grid key={`footer-item-${item.id}`} item className={classes.menuItem}>
                <Typography
                  key={`item-${item.id}`}
                  className={classes.title}
                >
                  {item.title}
                </Typography>
                {item.subitems.map(subitem =>
                  <Typography
                    key={`item-${subitem.id}`}
                    className={classes.subtitle}
                    onClick={() => {
                      navigate(item.link, {
                        state: {
                          subItemId: subitem.id
                        }
                      });
                    }}
                  >
                    {subitem.title}
                  </Typography>
                )}
              </Grid>
            ))}
          </div>
        </Grid>
      </Grid>
      <Grid item container xs={12}>
        <Divider className={classes.dividerHorizontal} />
      </Grid>
      <Grid item container xs={12} justify={'center'}>
        <Typography align={'center'} className={classes.footerText}>
          {`©Copyright ${new Date().getFullYear()} - DEKODING`}
        </Typography>
      </Grid>
    </Grid>
  );
};
